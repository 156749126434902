import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/partners';

import HighlightCards from 'components/content-blocks/highlight';
import MediaObject from 'components/content-blocks/media-object';
import Yoast from 'components/globals/yoast';
import { HeroEnterprise } from 'components/hero/enterprise';
import FranchiseInformation from 'components/templates/franchise-information';

import './styles/shamrock-foods.scss';

const Shamrock = () => {
  const data = useStaticQuery(graphql`
  {
    allWordpressPage(filter: {slug: {eq: "shamrock-foods"}}) {
      edges {
        node {
          wordpress_id
          yoast_head_json {
            title
            description
            canonical
            og_title
            og_description
            og_image {
              url {
                source_url
              }
            }
            og_url
            og_type
            twitter_title
            twitter_description
            twitter_image {
              source_url
            }
          }
          acf {
            hero {
              title
              sub_title
              copy
              schedule_demo {
                button_text
              }
              background_desktop_image {
                source_url
              }
              background_mobile_image {
                source_url
              }
            }
            media_object {
              body
              sub_title
              title
              desktop_image {
                alt_text
                source_url
              }
              mobile_image {
                alt_text
                source_url
              }
              button_url
              button_text
            }
            cards_1 {
              header {
                title
                sub_title
                copy
              }
              card {
                header
                image {
                  source_url
                }
                title
                copy
                link_url
                link_text
              }
            }
            franchise_information {
              layout
              schedule_demo_button_text
              content
              location
              name
              logos
              hubspot_meeting_url
              customer_review {
                quote
                company_info
                no_image
                desktop_image {
                  source_url
                }
                mobile_image {
                  source_url
                }
              }
              trial_length
              company_information {
                stats_text
                stats_number
                logo {
                  source_url
                }
                resources {
                  resource_1 {
                    resource_text
                    resource_url {
                      source_url
                    }
                    resource_link
                  }
                  resource_2 {
                    resource_text
                    resource_url {
                      source_url
                    }
                    resource_link
                  }
                  resource_3 {
                    resource_text
                    resource_url {
                      source_url
                    }
                    resource_link
                  }
                  resource_4 {
                    resource_text
                    resource_url {
                      source_url
                    }
                    resource_link
                  }
                  resource_5 {
                    resource_text
                    resource_url {
                      source_url
                    }
                    resource_link
                  }
                  resource_6 {
                    resource_text
                    resource_url {
                      source_url
                    }
                    resource_link
                  }
                }
              }
              buttons {
                signupmodallaunch
              }
              franchise_wistia_id
            }
          }
        }
      }
    }
  }  
  `);
  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const hero = pageContext.hero;
  const cards1 = pageContext.cards_1;
  const mediaObject = pageContext.media_object;
  const franchiseInformation = pageContext.franchise_information;

  return (
    <Layout>
      <Yoast { ...yoast } />
      <div className="shamrock-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroEnterprise
          { ...hero }
          uniqueId="shamrockFoods"
          data-section="shamrockFoods"
          scheduleDemoClassName="hero button is-primary is-rounded"
        />
        <FranchiseInformation { ...franchiseInformation } />
        <HighlightCards
          { ...cards1 }
          sub_title={ cards1.header.title }
          hasTitle
          hasCopy
          hasImage
        />
        <MediaObject { ...mediaObject[0] } />
      </div>
    </Layout>
  );
};
export default Shamrock;